import { Trainer } from './../../api/interfaces/trainer'

import { Company } from './../../api/interfaces/company'
import { TrainingLocation } from './../../api/interfaces/traininglocation'
import router from '@/router'
import {
  addDays,
  formatISO,
  isSameDay,
} from 'date-fns'
import { Ref, unref, ref, computed, watchEffect, onMounted } from '@vue/composition-api'
import Filters from '@/views/dashboard/planning/types/Filters'
import http from '@/plugins/axios'
import Query from '@/views/dashboard/planning/types/Query'
import UserModule from '@/store/modules/user'

export default function useFiltersInUrl () {
  const paramterUrl = {
    ...router.app.$route.query,
  }

  const filters = ref(new Filters(
    arrayFromUrl(paramterUrl.trainingType),
    arrayFromUrl(paramterUrl.area),
    arrayFromUrl(paramterUrl.trainer).map((trainerIRI) => { return { '@id': trainerIRI } as Trainer }),
    arrayFromUrl(paramterUrl.status),
    booleanOrNullFromUrl(paramterUrl.technicalPreparation),
    arrayFromUrl(paramterUrl.company).map((companyIRI) => { return { '@id': companyIRI } as Company }),
    arrayFromUrl(paramterUrl.trainingLocation).map((locationIRI) => { return { '@id': locationIRI } as TrainingLocation }),
    booleanFromUrl(paramterUrl.displayCanceled),
  )) as Ref<Filters>

  onMounted(() => {
    filters.value.reloadSelectedEntities(async (entityIRI: string) => {
      const response = await http.get(entityIRI)
      return response.data as Company
    })
  })

  const userConnected = UserModule.state.userConnected
  const currentDay: Ref<string> = ref('')
  if (userConnected.userApplication.filtersPlanning && userConnected.userApplication.filtersPlanning.filters.currentDay !== '') {
    currentDay.value = userConnected.userApplication.filtersPlanning.filters.currentDay
  } else {
    currentDay.value = paramterUrl.currentDay ? formatISO(new Date(paramterUrl.currentDay as string)) : formatISO(new Date())
  }
  const start = computed(() => {
    return formatISO(addDays(new Date(currentDay.value), -3).setHours(0, 0, 0, 0))
  })
  const end = computed(() => {
    return formatISO(addDays(new Date(currentDay.value), 4).setHours(23, 59, 0, 0))
  })
  const logisticArea = ref(paramterUrl.logisticArea as string || null)

  const query = computed((): Query => {
    return new Query(
      unref(start),
      unref(end),
      unref(currentDay),
      unref(logisticArea),
    )
  })
  watchEffect(async () => {
    addParamsToLocation(currentDay.value,
      filters.value, logisticArea.value as string)
  })
  return {
    currentDay,
    filters,
    query,
    logisticArea,
  }
}

export function arrayFromUrl (param: string | (string | null)[]): string[] {
  if (!param) {
    return []
  }
  if (Array.isArray(param)) {
    return [...param] as string[]
  }
  return [param]
}

function booleanFromUrl (param: string | (string | null)[]): boolean {
  return !!param && param === 'true'
}

function booleanOrNullFromUrl (param: string | (string | null)[]): boolean | null {
  return param ? param === 'true' : null
}

function addParamsToLocation (currentDay: string,
  filters: Filters, logisticArea: string) {
  const filterToSend = filters.paramsUrl
  if (!isSameDay(new Date(currentDay), new Date())) {
    filterToSend.currentDay = currentDay
  }
  filterToSend.logisticArea = logisticArea
  if (!shallowEqual(router.app.$route.query, filterToSend)) {
    router.replace({
      name: 'Planning',
      query: { ...filterToSend } as {
        [k: string]: string | (string | null)[] | null;
      },
    }).catch((error) => {
      // to not send error "NavigationDuplicated: Avoided redundant navigation to current location"
      // console.error(error)
      return error
    })
  }
}

function shallowEqual (object1: { [x: string]: any; }, object2: { [x: string]: any; }) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}
