








































































































































































































































































































































import frLocale from 'date-fns/locale/fr'
import { defineComponent } from '@vue/composition-api'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { format, formatISO, getDay, addDays, isSameDay } from 'date-fns'
import { GridLayout, GridItem } from 'vue-grid-layout'
import type { ItemWithPosition, CalendarDay } from './types/Calendar'
import IsInTheFilters from '@/composables/planning/inTheFilters'
import type { TrainingSession } from '@/api/interfaces/trainingsession'
import { TrainingType } from '@/api/interfaces/trainingtype'
import useFiltersInUrl from '@/composables/planning/paramsUrlPlanning'
import { RawLocation } from 'vue-router'
import store from '@/store'
import Filters from '@/views/dashboard/planning/types/Filters'
import { userConnectedHasRole } from '@/composables/UserGrant'
import { EventDay } from '@/api/interfaces/eventday'

export default defineComponent({
  setup () {
    const canDeleteEventDay = () => userConnectedHasRole('ROLE_DELETE_EVENT_DAY')
    const {
      currentDay,
      filters,
      query,
      logisticArea,
    } = useFiltersInUrl()
    return {
      currentDay,
      filters,
      query,
      logisticArea,
      canDeleteEventDay,
    }
  },
  components: {
    GridLayout,
    GridItem,
    Filters: () => import('@/components/molecules/planning/m-planning-filters.vue'),
    MGridItemContent: () => import('@/components/molecules/planning/m-grid-item-content.vue'),
    EventComparative: () => import('@/components/molecules/m-event-comparative.vue'),
    TopBar: () => import('@/components/organisms/o-top-bar.vue'),
    ListLogisticSessionLogger: () =>
  import('@/components/molecules/logisticSession/listLogisticSessionLogger.vue'),
    DeleteEventDay: () => import('@/components/molecules/dialog/deleteEventDay.vue'),
  },
  name: 'Planning',
  data () {
    return {
      dialogInfoSession: false,
      idModalToOpen: null as number | null,
      dialogMerge: false,
      ready: false,
      colorShadow: 'red',
      lockHorizontal: {
        startAxis: 'y',
        lockAxis: 'y',
      },
      preventCollision: false,
      test: [],
      color: 'black',
      layout: [] as ItemWithPosition[],
      title: this.$t('calendar.title'),
      resizable: false,
      draggable: true,
      merge: {} as Partial<{ itemToMerge: number; itemToMergeInto: number }>,
      tab: null,
      dialogDeleteEventDay: false,
      eventDayToDelete: null as EventDay | null,
    }
  },
  created () {
    this.loadTrainer()
    this.setFiltersPlanningFromUserConnected()
    window.addEventListener('mouseup', this.mouseUpOnCard)
    this.loadLogisticSession()
    this.loadEventDaysWithFilters()
  },
  computed: {
    ...mapGetters('user', {
      userConnectedIsTrainerRegionManager: 'isTrainerRegionManager',
    }),
    ...mapGetters('calendar', {
      listDTO: 'listDTO',
    }),
    ...mapGetters('eventDay', {
      eventDays: 'eventDays',
    }),
    ...mapState('user', {
      userConnected: 'userConnected',
    }),
    ...mapState('calendar', {
      listCalendarDays: 'calendarDays',
      listLogisticSession: 'logisticSessions',
      loading: 'loading',
    }),
    ...mapState('trainerList', {
      listTrainer: 'list',
    }),
    ...mapState('trainingSessionList', {
      trainingSessionList: 'list',
      totalItems: 'totalItems',
    }),
    modalInfosSession (): any {
      if (!this.idModalToOpen) return {}
      const session = this.listDTO.find((session: TrainingSession) => session.id === this.idModalToOpen)
      // TODO put the url
      const baseUrl = window.location.origin
      const route: RawLocation = { name: 'LogisticSession Edit', params: { idLogisticSession: session.logisticSession.id.toString() } }
      session.url = baseUrl + this.$router.resolve(route).href
      session.route = route
      return session
    },
    listDates (): any {
      return [
        { position: getDay(addDays(new Date(this.currentDay), -3)), date: formatISO(addDays(new Date(this.currentDay), -3)), order: 0 },
        { position: getDay(addDays(new Date(this.currentDay), -2)), date: formatISO(addDays(new Date(this.currentDay), -2)), order: 1 },
        { position: getDay(addDays(new Date(this.currentDay), -1)), date: formatISO(addDays(new Date(this.currentDay), -1)), order: 2 },
        { position: getDay(addDays(new Date(this.currentDay), 0)), date: formatISO(addDays(new Date(this.currentDay), 0)), order: 3 },
        { position: getDay(addDays(new Date(this.currentDay), 1)), date: formatISO(addDays(new Date(this.currentDay), 1)), order: 4 },
        { position: getDay(addDays(new Date(this.currentDay), 2)), date: formatISO(addDays(new Date(this.currentDay), 2)), order: 5 },
        { position: getDay(addDays(new Date(this.currentDay), 3)), date: formatISO(addDays(new Date(this.currentDay), 3)), order: 6 },
      ]
    },
    todayOrder () : number | null {
      return this.listDates.find((date: any) => {
        return this.isToday(date.date)
      })?.order ?? null
    },
    listDatesWithEvent () : any {
      return this.listDates.map((date: any) => {
        return { ...date, events: this.getEventsIfExist(date.date) }
      })
    },
    displayedLayout (): ItemWithPosition[] {
      return this.layout.filter((item) => item.isDisplayed)
    },
  },
  destroyed () {
    // remove the listeners on destroy
    window.removeEventListener('mouseup', this.mouseUpOnCard)
    document.removeEventListener('mousemove', this.removeIdModal)
  },
  watch: {
    query: {
      deep: true,
      handler: async function (newValue, oldValue) {
        this.loadLogisticSession()

        if (newValue.start !== oldValue.start || newValue.end !== oldValue.end) {
          this.loadEventDaysWithFilters()
        }

        this.setStoreFilters(this.filters.paramsUrl)
        this.setStoreQuery(newValue)
        await this.saveFiltersPlanning()
      },
    },
    dialogMerge: {
      deep: true,
      handler: function (newValue) {
        if (!newValue) {
          this.preventCollision = false
          this.merge = {}
        }
      },
    },
    filters: {
      deep: true,
      handler: async function (newValue, oldValue) {
        IsInTheFilters.updateValue(newValue, this.layout, this.userConnectedIsTrainerRegionManager)
        this.layout = IsInTheFilters.dtoFiltered

        if (this.query.logisticArea) {
          this.setStoreFilters(newValue.paramsUrl)
          this.setStoreQuery(this.query)
          await this.saveFiltersPlanning()
        }
      },
    },
    'filters.area': {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.loadEventDaysWithFilters()
        }
      },
    },
  },
  methods: {
    ...mapActions('calendar', {
      load: 'load',
      resetCalendar: 'reset',
      postCalendarDay: 'postCalendarDay',
      putCalendarDay: 'putCalendarDay',
      mergeSessions: 'merge',
    }),
    ...mapActions('filtersPlanning', {
      saveFiltersPlanning: 'save',
    }),
    ...mapActions('user', {
      sendErrorMessage: 'sendErrorMessage',
    }),
    ...mapActions('trainerList', {
      loadTrainer: 'load',
    }),
    ...mapActions('eventDay', {
      loadEventDays: 'load',
    }),
    ...mapMutations('filtersPlanning', {
      setStoreFilters: 'setFilters',
      setStoreQuery: 'setQuery',
    }),
    loadLogisticSession () {
      if (this.query.logisticArea) {
        this.load({
          'startAt[after]': format(new Date(this.query.start), 'yyyy-MM-dd'),
          'startAt[strictly_before]': format(new Date(this.query.end), 'yyyy-MM-dd'),
          logisticArea: this.query.logisticArea,
        }).then(() => {
          this.buildLayout()
        })
      }
    },
    loadEventDaysWithFilters () {
      this.loadEventDays({
        'dateEvent[after]': format(new Date(this.query.start), 'yyyy-MM-dd'),
        'dateEvent[strictly_before]': format(new Date(this.query.end), 'yyyy-MM-dd'),
        areas: this.filters.area,
      })
    },
    removeIdModal () {
      this.idModalToOpen = null
    },
    changeCurrentDay (currentDay: any) {
      this.currentDay = currentDay
    },
    changeLogisticArea (logisticArea: any) {
      // console.log('changeLogisticArea', logisticArea)
      this.logisticArea = logisticArea
    },
    isToday (date: string) {
      // console.log('isToday', date)
      return isSameDay(new Date(), new Date(date))
    },
    formatDay (date: string) {
      return format(new Date(date), 'E', {
        locale: frLocale,
      }).toUpperCase()
    },
    buildLayout () {
      if (this.listDTO.length > 0) {
        this.listDTO.forEach((dto: ItemWithPosition) => {
          dto.x = this.listDates.find((date: {order: number, date: string, position: number}) => isSameDay(new Date(dto.logisticSession.startAt as string), new Date(date.date))).order
        })
        IsInTheFilters.updateValue(this.filters, this.listDTO, this.userConnectedIsTrainerRegionManager)
        this.layout = IsInTheFilters.dtoFiltered
      } else {
        this.layout = []
      }
      return []
    },
    layoutReadyEvent: function (newLayout: any) {
      this.ready = true
    // console.log('Ready layout: ', newLayout)
    },
    mouseDownOnCard: function (item: ItemWithPosition) {
      this.idModalToOpen = item.i
      this.colorShadow = (item.logisticSession.trainingSessions[0].trainingType as TrainingType).color || ''
      // console.log(this.idModalToOpen)
      this.merge.itemToMerge = item.i
      this.preventCollision = true
      document.addEventListener('mousemove', this.removeIdModal)
    },
    mouseUpOnCard: function () {
      document.removeEventListener('mousemove', this.removeIdModal)
      if (this.merge && this.merge.itemToMerge && this.merge.itemToMergeInto) {
        this.dialogMerge = true
      } else {
        this.preventCollision = false
      }
    },
    async mergeTwoItems () {
      const layoutBeforeChanges = this.layout
      try {
        const itemToMergeInto = this.layout.find(
          (item) => item.i === this.merge.itemToMergeInto,
        )
        const itemToMerge = this.layout.find((item) => item.i === this.merge.itemToMerge)
        if (itemToMerge && itemToMergeInto) {
          this.layout = this.layout.filter((item) => item.i !== this.merge.itemToMerge)
          await this.mergeSessions({ idMergeInto: itemToMergeInto.i, idDelete: itemToMerge.i }).then((r) => {
            this.buildLayout()
          })
        }
      } catch {
        this.layout = layoutBeforeChanges
      } finally {
        this.dialogMerge = false
        this.merge = {}
      }
    },
    moveEvent: function (i: string, newX: string, newY: string) {
      this.idModalToOpen = null
      const msg = 'MOVE i=' + i + ', X=' + newX + ', Y=' + newY
      // console.log(msg)
      const itemToMergeInto = this.layout?.find((item) => {
    return (
      item.x === Number(newX) &&
      item.y <= Number(newY) &&
      item.y + item.h > Number(newY) &&
      Number(i) !== item.i
    )
  })
      this.merge.itemToMergeInto = itemToMergeInto?.i
    },
    movedEvent: function (i: string, newX: string, newY: string) {
      const msg = 'MOVED i=' + i + ', X=' + newX + ', Y=' + newY
      // console.log(msg)
      this.mouseUpOnCard()
      this.sendCalendarDays(this.layout.filter((item) => item.x === Number(newX)))
    },
    async copy (mytext: string) {
      try {
        await navigator.clipboard.writeText(mytext)
      } catch ($e) {
      }
    },
    sendCalendarDays (layout: ItemWithPosition[]) {
      if (this.ready) {
        this.listDates.forEach((day: {position: number, date: Date}, index: number) => {
          const calendarDay = {
            logisticArea: this.query.logisticArea,
            date: day.date,
            positions:
              layout
                .filter((session) => session.x === index && !session.isCanceled)
                .map((session) => {
                  return {
                    y: session.y,
                    i: session.i,
                  }
                }),
          }
          if (calendarDay.positions.length === 0) return
          if (this.listCalendarDays.length > 0) {
            const idCalendarDay = this.listCalendarDays?.find((x: CalendarDay) =>
            isSameDay(new Date(x.date), new Date(calendarDay.date)),
          )?.id
            if (idCalendarDay) {
              this.putCalendarDay({ id: idCalendarDay, calendarDay: calendarDay })
              return
            }
          }
          this.postCalendarDay({ ...calendarDay })
        })
      }
    },
    getEventsIfExist: function (date: string): Array<string> {
      const events = []
      for (let i = 0; i < this.eventDays.length; i++) {
        if (this.eventDays[i].dateEvent && this.removeTimePart(this.eventDays[i].dateEvent) === this.removeTimePart(date)) {
          events.push(this.eventDays[i])
        }
      }
      return events
    },
    removeTimePart: function (date:string): string {
      return date.substring(0, 10)
    },
    setFiltersPlanningFromUserConnected () {
      if (this.userConnected.userApplication.filtersPlanning) {
        const filtersPlanningUser = this.userConnected.userApplication.filtersPlanning.filters
        this.filters = new Filters(
          filtersPlanningUser.trainingType,
          filtersPlanningUser.area,
          filtersPlanningUser.trainer ? filtersPlanningUser.trainer.map((iri: string) => ({ '@id': iri })) : [],
          filtersPlanningUser.status,
          filtersPlanningUser.technicalPreparation,
          filtersPlanningUser.company ? filtersPlanningUser.company.map((iri: string) => ({ '@id': iri })) : [],
          filtersPlanningUser.trainingLocation ? filtersPlanningUser.trainingLocation.map((iri: string) => ({ '@id': iri })) : [],
          filtersPlanningUser.displayedCancel,
        )
      }
    },
    openModalDeleteEventDay (event: EventDay) {
      this.eventDayToDelete = event
      this.dialogDeleteEventDay = true
    },
  },
  beforeRouteUpdate (to, _from, next) {
    store.commit('navigation/removeRoute')
    store.commit('navigation/addRoute', to)
    next()
  },
})
