import { Trainer } from './../../../../api/interfaces/trainer'
import { Entity } from './../../../../api/interfaces/entity'
import { TrainingLocation } from './../../../../api/interfaces/traininglocation'
import { Company } from './../../../../api/interfaces/company'
export default class Filters {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public trainingType: string[] = [],
    public area: string[] = [],
    public trainer: Trainer[] = [],
    public status: string[] = [],
    public technicalPreparation: boolean | null = null,
    public company: Company[] = [],
    public trainingLocation: TrainingLocation[] = [],
    public displayCanceled: boolean = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) {}

  public get paramsUrl (): Record<string, string|string[]> {
    const entries = Object.entries(this)
    const arrayEntries = entries
        .filter(([, value]) => !!value && Array.isArray(value) && value.length > 0)
        .map(([key, value]) => [key, value.map((e: Entity) => e['@id'] ?? e)])
    const booleanEntries = entries.filter(([key, value]) => {
      if (key === 'displayCanceled') {
        return value
      }
      if (key === 'technicalPreparation') {
        return value !== null
      }
      return false
    })
    return Object.fromEntries([...arrayEntries, ...booleanEntries])
  }

  public async reloadSelectedEntities (load: (iri:string) => Promise<Entity>): Promise<void> {
    const companiesToLoad = this.company.filter((company) => company['@id'] && !company.name)
    if (companiesToLoad.length > 0) {
      Promise.all(companiesToLoad.map((company) => load(company['@id'] as string) as Promise<Company>))
      .then((companiesReloaded) => {
        this.company = companiesReloaded
      })
    }

    const locationsToLoad = this.trainingLocation.filter((location) => location['@id'] && !location.name)
    if (locationsToLoad.length > 0) {
      Promise.all(locationsToLoad.map((location) => load(location['@id'] as string) as Promise<TrainingLocation>))
      .then((locationsReloaded) => {
        this.trainingLocation = locationsReloaded
      })
    }

    const trainersToLoad = this.trainer.filter((trainer) => trainer['@id'] && !trainer.firstName && !trainer.lastName)
    if (trainersToLoad.length > 0) {
      Promise.all(trainersToLoad.map((trainer) => load(trainer['@id'] as string) as Promise<Trainer>))
      .then((trainersReloaded) => {
        this.trainer = trainersReloaded
      })
    }
  }
}
